import React, { useState, useEffect,useCallback } from 'react'
import CompanyStore from '../../../stores/CompanyStore'
import AuthActions from '../../../actions/AuthActions';
import AccommodationStore from "../../../stores/AccommodationStore"
import '../../../styles/header.css'

function Header(props:PropsValue) {

  const [comany_name, setCompanyName] = useState([]);
  const [accommdation, setAccommodation] = useState(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableCompanyChangeHandlerr = useCallback(companyChangeHandler, [])
  function companyChangeHandler(){
    const company = CompanyStore.getInfo()
    setAccommodation(AccommodationStore.getById(props.accommodation_id))
    if(company){
      setCompanyName(company.name)
    }
  }
  useEffect(() => {
    CompanyStore.addChangeListener(stableCompanyChangeHandlerr)
    stableCompanyChangeHandlerr()
    return function cleanup() {
      CompanyStore.removeChangeListener(stableCompanyChangeHandlerr)
    };
  },[stableCompanyChangeHandlerr]);



    function logout(){
      AuthActions.logout()
    }
  return(
    <div id="header">
      <header>
        {props.title ? <h1 className="title h-item">{props.title}</h1> : null}
        {accommdation ? <h1 className="header-accommdation"><div style={{backgroundColor: '#' + accommdation.color}} className="acc_icon">{accommdation.short_name}</div>{accommdation.name}</h1> : null}
        <div className="company-name h-item">{comany_name}</div>
        <div className="logout" onClick={logout}><i className="fas fa-sign-out-alt"></i> ログアウト</div>
      </header>
      {accommdation ?
        <div className="accommdation-menu">
          <div className={(props.id === "calendar" ? "selected" : "") + " accommdation-menu-item"}><a href={"/m/accommodation/" + accommdation.id}>カレンダー</a></div>
          <div className={(props.id === "cash" ? "selected" : "") + " accommdation-menu-item"}><a href={"/m/accommodation/" + accommdation.id + "/cash"}>小口現金</a></div>
          <div className={(props.id === "info" ? "selected" : "") + " accommdation-menu-item"}><a href={"/m/accommodation/" + accommdation.id + "/setting"}>詳細設定</a></div>
          <div className={(props.id === "plan" ? "selected" : "") + " accommdation-menu-item"}><a href={"/m/accommodation/" + accommdation.id + "/plan"}>プラン</a></div>
          <div className={(props.id === "room" ? "selected" : "") + " accommdation-menu-item"}><a href={"/m/accommodation/" + accommdation.id + "/room"}>部屋</a></div>
          <div className={(props.id === "option" ? "selected" : "") + " accommdation-menu-item"}><a href={"/m/accommodation/" + accommdation.id + "/option"}>オプション</a></div>
          <div className={(props.id === "faq" ? "selected" : "") + " accommdation-menu-item"}><a href={"/m/accommodation/" + accommdation.id + "/faq"}>FAQ</a></div>
        </div>
        : null
      }
    </div>
  )
}

export default Header
